.footer {
  width: 100%;
  height: 50px;
  position: absolute;
  display: block;
  bottom: 0;
  @include media("<=phone") {
    position: relative;
  }
  .footer-inner {
    display: flex;
    justify-content: space-between;
    .make-with-love {
      &:hover {
        &:after {
          position: absolute;
          content: "";
          left: -5px;
          bottom: -15%;
          width: 160px;
          height: 2px;
          background: $dark;
        }
      }
    }
    .social-links {
      a {
        text-decoration: none;
        color: $dark;
        padding: 5px 10px;
        text-transform: uppercase;
        &:hover {
          background-color: $primary;
          color: $light;
        }
      }
    }
  }
}
