.about-section {
  display: flex;
  position: relative;
  align-items: center;
  @include media("<=phone") {
    height: auto;
    padding-bottom: 20px;
  }
  .about {
    width: 80%;
    margin: 0 auto;
    position: relative;
    text-align: center;
    .titulo {
      h2 {
        font-size: 3rem;
        text-transform: uppercase;
        letter-spacing: 10px;
      }
    }
    .bio {
      max-width: 500px;
      p {
        text-align: left;
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: 300;
      }
    }
  }
}
.vertical-text {
  position: fixed;
  top: 50%;
  right: 0px;
  transform: rotate(-90deg);
  z-index: 1;
  @include media("<=phone") {
    right: -80px;
  }
  p {
    text-transform: uppercase;
    font-size: 1rem;
    opacity: 0.9;
    font-weight: 700;
    &:before {
      content: "";
      top: 50%;
      left: -50px;
      width: 40px;
      height: 1px;
      background-color: #020202;
      position: absolute;
    }
    &:after {
      content: "";
      top: 50%;
      right: -50px;
      width: 40px;
      height: 1px;
      background-color: #020202;
      position: absolute;
    }
  }
}
