.home-page {
  height: auto;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 30px 0;
  @include media("<=phone") {
    height: auto;
    padding: 40px 0;
  }
  .inner-home-page {
    padding-top: 0px;
    @include media("<=phone") {
    }
    .titulo {
      width: 70%;
      @include media("<=phone") {
        width: 100%;
        padding: 0;
      }
      h2 {
        font-family: "Bebas Neue";
        font-size: 5.6rem;

        text-transform: uppercase;
        line-height: 1;
        margin: 30px 0;
        @include media("<=phone") {
          font-size: 4rem;
          letter-spacing: 5px;
          margin: 15px 0;
          text-align: center;
        }
      }
    }
    .titles {
      @include media("<=phone") {
        padding: 0 10px;
        text-align: center;
      }
      .inner-titles {
        span {
          padding: 5px 10px;
          background-color: whitesmoke;
          margin: 0 5px;
          font-weight: 600;
          @include media("<=phone") {
            margin: 2px 0;
            background-color: white;
          }
          &:hover {
            background-color: $light;
            color: $dark;
            padding: 5px 10px;
          }
          @include media("<=phone") {
            display: block;
          }
        }
      }
    }
    .description {
      p {
        font-size: 1.2rem;
        @include media("<=phone") {
          text-align: center;
          padding: 0 20px;
        }
        code {
          background-color: whitesmoke;
          color: $secondary;
          font-size: 1.2rem;
          font-weight: normal;
          padding: 0em 0.5em 0.25em;
          -webkit-font-smoothing: auto;
          @include media("<=phone") {
            text-align: center;
            font-size: 1.1rem;
          }
        }
      }
      @include media("<=phone") {
        padding: 0 30px;
      }
    }
  }
}
