//Fonts titles
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Days+One&family=Fira+Sans+Condensed:wght@900&family=Krona+One&display=swap");
// font-family: 'Bebas Neue', cursive;
// font-family: 'Fira Sans Condensed', sans-serif;
// font-family: 'Krona One', sans-serif;
// font-family: 'Days One', sans-serif;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap");

// Define colors

$dark: #00171f;
$primary: #003459;
$secondary: #007ea7;
$alternative: #00a8e8;
$light: #ffffff;

// Import media queries
@import "./Media.scss";
@import "./Header.scss";
@import "./Home.scss";
@import "./About.scss";
@import "./Footer.scss";
@import "./Blog.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.container {
  position: relative;
  width: 90%;
  min-width: 90%;
  margin: 0 auto;
  @include media("<=phone") {
    width: 95%;
    min-width: 95%;
    margin: 0 auto;
  }
  .wrapper {
    position: relative;
    padding: 0 20px;
    align-items: center;
    @include media("<=phone") {
      padding: 0 10px;
    }
  }
}
.home {
  display: flex;
  height: 90vh;
  align-items: center;
}
