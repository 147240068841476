header {
  height: 60px;
  width: 100%;
  background: $light;
  position: relative;
  @include media("<=phone") {
    height: 60px;
    position: relative;
    top: 0;
    z-index: 1;
    display: block;
  }
  .inner-header {
    width: 100%;
    position: relative;
    z-index: 18;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media("<=phone") {
      height: 60px;
    }
    .logo a {
      font-weight: 300;
      text-decoration: none;
      color: $dark;
      font-size: 1.2rem;
      @include media("<=phone") {
        font-size: 1.2rem;
      }
    }
    .menu {
      button {
        font-family: "Dosis";
        font-weight: 300;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        color: $dark;
        font-size: 1.2rem;
        @include media("<=phone") {
          font-family: "Dosis";
          font-size: 1.2rem;
        }
      }
    }
  }
  // Full height menu
  .full-height-menu {
    display: none;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    .menu-secondary-background-color {
      background-color: $secondary;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
    .menu-layer {
      position: relative;
      background: $primary;
      height: 100%;
      overflow: hidden;
      .menu-hobbies-background {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-size: cover;
        background-repeat: no-repeat;
        animation: cameraPan 10s infinite;
      }
      .wrapper {
        position: relative;
        padding-top: 80px;
        @include media("<=phone") {
          padding-top: 10px;
        }
        .menu-links {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          top: 100px;
          width: 100%;
          @include media("<=phone") {
            display: block;
            top: 90px;
            justify-content: flex-end;
          }
          nav {
            display: block;
            width: 60%;
            @include media("<=phone") {
              width: 100%;
            }
            ul {
              padding: 0;
              margin: 0;
              li {
                list-style: none;
                font-size: 4.8rem;
                font-weight: 700;
                cursor: pointer;
                height: 120px;
                overflow: hidden;
                position: relative;
                width: 50%;
                @include media("<=phone") {
                  width: 100%;
                  font-size: 3rem;
                  height: 80px;
                }
                a {
                  position: absolute;
                  color: #fff;
                  text-decoration: none;
                  &:hover {
                    color: $alternative;
                  }
                }
              }
            }
          }
          .menu-info {
            width: 40%;
            color: #fff;
            @include media("<=phone") {
              width: 100%;
            }
            h2 {
              font-size: 2rem;
              margin: 8px auto;
              @include media("<=phone") {
                font-size: 1.1rem;
              }
            }
            p {
              margin: 0 auto;
              padding: 10px 0;
              font-size: 1rem;
            }
          }
        }
        .hobbies {
          position: absolute;
          bottom: -80px;
          color: #fff;
          margin-top: 16px;
          font-size: 0.9rem;
          font-weight: 600;
          @include media("<=phone") {
            margin-top: 10px;
          }
          span {
            &:first-child {
              margin-left: 64px;
              @include media("<=phone") {
                margin-left: 24px;
              }
            }
            font-weight: 400;
            cursor: pointer;
            margin: 0 32px;
            @include media("<=phone") {
              margin: 0 8px;
            }
            transition: 0.3s ease-in-out;
            &:hover {
              background: $dark;
              padding: 0 24px;
              border-radius: 4px;
              @include media("<=phone") {
                padding: 0 8px;
              }
            }
          }
        }
      }
    }
  }
}
// Styles for header

@keyframes cameraPan {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0% 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}
